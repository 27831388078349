import React from "react";
import Jobs from "../../components/jobs";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import cms from "../../components/cms/cms";

const USPage = () =>
  <Layout>
    <SEO
      keywords={[`${cms.keyword}  jobs united states`, `${cms.keyword}  jobs us`, `${cms.keyword}  developer jobs united states`, `${cms.keyword}  developer jobs us`, `remote ${cms.keyword}  jobs united states`, `remote ${cms.keyword}  jobs us`, `${cms.keyword}  software engineering jobs united states`, `${cms.keyword}  software engineering jobs us`]}
      title={`${cms.keyword}  Jobs in the United States`}
      description={`Looking for a ${cms.keyword} Developer job in the United States? ${cms.siteUrl} is exclusively dedicated to ${cms.keyword} jobs. Find ${cms.keyword}  jobs in New York, San Francisco, Seattle, Los Angeles and other places in the US.`}
    />
    <Jobs countryProp={["US"]} />
  </Layout>

export default USPage;